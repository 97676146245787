// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-complexicon-tsx": () => import("./../../../src/pages/complexicon.tsx" /* webpackChunkName: "component---src-pages-complexicon-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-robo-voice-tsx": () => import("./../../../src/pages/robo-voice.tsx" /* webpackChunkName: "component---src-pages-robo-voice-tsx" */),
  "component---src-pages-table-service-tsx": () => import("./../../../src/pages/table-service.tsx" /* webpackChunkName: "component---src-pages-table-service-tsx" */)
}

